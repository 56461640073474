<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in providers"
        :key="index")
        .list-1
          .list-1__wrapper
            .list-1__title {{ item.name }}
            .list-1__settings
              v-btn(
                icon
                v-if="'Technologist' === user.user.role"
                @click="openEdit(item)")
                  icon-gear.list-1__icon
          .list-1__item
            .list-1__item-label {{ 'base.provider' | translate }} / {{ 'base.buyer' | translate }}
            .list-1__item-value {{ item.type === 'buyer' ? 'base.buyer' : 'base.provider' | translate }}
          .list-1__item
            .list-1__item-label {{ 'base.info' | translate }}
            .list-1__item-value {{ item.info }}

      v-table-body(
        v-if="!loading"
        outside-table
        :empty="!providers.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 2" :key="`${i}-${j}`")
            .list-1__item-skeleton

    v-dialog(
      max-width="348"
      v-model="editModal.show")
      settings-modal(
        :item="editModal.item"
        @close="editModal.show = false")

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import IconGear from '@/components/icons/IconGear'
import SettingsModal from './modals/SettingsModal'

import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'SuppliersTableMobile',

  mixins: [paginationMixin],

  components: {
    IconGear,
    SettingsModal
  },

  data: () => ({
    editModal: {
      item: null,
      show: false
    }
  }),

  mounted () {
    this.setupPagination(this.fetchProviders)
  },

  methods: {
    ...mapActions(['fetchProviders']),

    openEdit (item) {
      this.editModal.item = item
      this.editModal.show = true
    }
  },

  computed: {
    ...mapGetters([
      'providers',
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
